<template>
  <div class="page">
    <div class="wrapper illustration illustration_fail">
      <Backlink title="questionnaire" :backlink="backlink" type="index" />
      <div class="content">
        <div class="content__container textpage">
          <h1>{{ title | localize }}</h1>
          <p>{{ text | localize }}</p>
          <div class="failure__image"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'

export default {
  name: 'Mortgage_payment',
  components: { Backlink },
  props: {
    backlink: { type: String, default: '/' },
    title: { type: String, default: 'fail_title' },
    text: { type: String, default: 'fail_text_1' }
  }
}
</script>
